import React from 'react';
import { get } from 'lodash';
import { FixPhoneIcon, PhoneIcon } from '../Icons';
import { Button } from '@mui/material';

export default function CallButton({
  type,
  card
}) {

  const pressOnPhone = (e) => {
    const value = get(card, 'product.content.phone.value', '');
    const prefix = get(card, 'product.content.phone.prefix', '');
    if (value) {
      window.location.href = `tel:${prefix + value}`;
      e.preventDefault();
    }
  }

  const phoneTypes = {
    phone: PhoneIcon,
    fix: FixPhoneIcon
  };

  const CallIcon = (props) => {
    if (phoneTypes.hasOwnProperty(type)) {
      const SelectedUrlIcon = phoneTypes[type];
      return <SelectedUrlIcon {...props} />;
    }
    return <></>;
  };

  return (
    <Button onClick={pressOnPhone}>
      <CallIcon />
    </Button>
  );
}
