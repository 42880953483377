import React from 'react';
import './card.scss';
import useFetch from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { AndroidView, IOSView } from 'react-device-detect';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import FlashMessage from '../../components/FlashMessage';
import Contact from '../../components/Contact';
import { Avatar, Box, Link, Typography, useTheme } from '@mui/material';
import AppStore from '../../assets/images/app_store_badge.svg';
import PlayStore from '../../assets/images/play_store_badge.svg';
import Logo from '../../assets/images/logo.svg';
import { AddIcon } from '../../components/Icons';

export default function Card() {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const { id } = useParams();
  const { data: card, loading, error } = useFetch(`${process.env.REACT_APP_CARD_API_BASE_URL}v1/cards/${id}`);

  return (
    <Box
      component='section'
      sx={{
        marginY: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
            m: 1,
            width: 350
          }}>
            <Box sx={{ flex: 1 }}>
              <img style={{ height: 30, width: 80 }} src={Logo} />
            </Box>
            <Box sx={{ alignSelf: 'flex-end' }}>
              <IOSView>
                <Link
                  href='https://apps.apple.com/fr/app/card4ever/id16449389234'
                  rel='noreferrer noopener'
                  target='_blank'
                  title='Card4ever IOS Mobile'>
                  <AddIcon />
                </Link>
              </IOSView>
              <AndroidView>
                <Link
                  href='https://play.google.com/store/apps/details?id=com.c4e'
                  rel='noreferrer noopener'
                  target='_blank'
                  title='Card4ever Android Mobile'
                >
                  <AddIcon />
                </Link>
              </AndroidView>

            </Box>
          </Box>
          <Box sx={{ maxWidth: 280, minHeight: 600 }}
            style={{
              backgroundColor: theme.palette.primary.main,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 23,
              padding: 20,
            }}>
            <Box sx={{
              display: 'flex',
              p: 1,
              m: 1
            }}>
              <Box sx={{ flex: 1, borderRadius: 100, }}>
                <Avatar sx={{ width: 100, height: 100 }} src={`https://assets.card4ever.com/${card?.avatarPath}?version=${Date.now()}`} />
              </Box>
              <Box sx={{ alignSelf: 'flex-end' }}>
                <img style={{ height: 100, width: 100, borderRadius: 15 }} src={`https://qrcodes.card4ever.com/${card.type}/${card.id}.png`} />
              </Box>
            </Box>
            <Box sx={{ marginTop: 5, display: 'flex', justifyContent: 'center' }}>
              <Typography variant='h4' color='#FFFFFF'>{t(card?.title)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='subtitle1' color='#FFFFFF' gutterBottom>
                {card?.product?.content?.job.value}
              </Typography>
            </Box>
            <Contact
              card={card}
            />
            {error ? (
              <FlashMessage duration={5000}>
                <div className='error-message'>{error}</div>
              </FlashMessage>
            ) : null}
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            p: 1,
            m: 1
          }}>
            <Typography variant='body1' color='secondary' gutterBottom>
              Pour enregistrer ce profile
            </Typography>

            <Typography variant='body1' color='secondary' gutterBottom>
              Télécharger l'application
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            width: 350,
            p: 1,
            m: 1
          }}>
            <Box sx={{ flex: 1 }}>
              <Link
                href='https://play.google.com/store/apps/details?id=com.c4e'
                rel='noreferrer noopener'
                target='_blank'
                title='Card4ever Android Mobile'
              >
                <img src={`${PlayStore}`} />
              </Link>
            </Box>
            <Box sx={{ alignSlef: 'flex-end' }}>
              <Link
                href='https://apps.apple.com/fr/app/card4ever/id6449389234'
                rel='noreferrer noopener'
                target='_blank'
                title='Card4ever IOS Mobile'>
                <img src={`${AppStore}`} />
              </Link>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}