import React, { useState } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CompanyIcon } from '../Icons';
import { Box, Button, Modal, Typography } from '@mui/material';

export default function TextButton({
  type = '',
  card,
}) {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const urlTypes = {
    company: CompanyIcon
  };

  const UrlIcon = (props) => {
    if (urlTypes.hasOwnProperty(type)) {
      const SelectedUrlIcon = urlTypes[type];
      return <SelectedUrlIcon {...props} />;
    }
    return <></>;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              {t(get(card, `product.content.${type}.label`, ''))}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography id='modal-modal-description'>
              {t(get(card, `product.content.${type}.value`, ''))}
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Button onClick={handleOpen}>
        <UrlIcon />
      </Button>
    </>
  );
}
