import React from 'react';
import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import './assets/css/style.css';

const Pricing = () => {
  return (
    <React.Fragment>
      <section className="section pricing" id="pricing">
        <Container>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">Plans Tarifaires</h2>
                <span className="heading"></span>
              </div>
            </div>
          </div>
          <Row className="justify-content-center">
            <Col lg={4} md={6} mt={4}>
              <div className="pricing-box text-center">
                <div className="px-4 py-5">
                  <div className="pricing-icon text-primary">
                    <h3>25<sup>TND</sup></h3>
                  </div>
                  <h5 className="my-4 box-heading">BASIQUE</h5>
                  <ul className="pricing-team pricing-list list-unstyled mt-2 mb-0">
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Carte standard</li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Personnalisation Prénom, Nom et Profession</li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> QR Code</li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Notification d'ajout dans d'autres portefeuilles</li>
                    <li className="pricing-item"><del className="text-muted">Personnalisation des informations de contact</del></li>
                    <li className="pricing-item"><del className="text-muted">Updates</del></li>
                    <li className="pricing-item"><del className="text-muted">Consulter qui vous a ajouté dans son portefeuille</del></li>
                    <li className="pricing-item"><del className="text-muted">Un badge plastifié de protection</del></li>
                    <li className="pricing-item"><del className="text-muted">Premium support</del></li>
                  </ul>
                  <Button to="#" className="btn bg-gradiant mt-4 w-100">Sign in</Button>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} mt={4}>
              <div className="pricing-box pricing-active text-center">
                <div className="px-4 py-5">
                  <div className="pricing-icon text-primary">
                    <h3>35<sup>TND</sup></h3>
                  </div>
                  <h5 className="my-4 box-heading">PREMIUM</h5>
                  <ul className="pricing-team pricing-list list-unstyled mt-2 mb-0">
                    <li className="pricing-item">
                      <i className="uil uil-check-circle text-primary">
                      </i> Carte avec couleur</li>
                    <li className="pricing-item">
                      <i className="uil uil-check-circle text-primary">
                      </i> Personnalisation Prénom, Nom et Profession</li>
                    <li className="pricing-item">
                      <i className="uil uil-check-circle text-primary">
                      </i> QR Code</li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Notification d'ajout dans d'autres portefeuilles</li>
                    <li className="pricing-item">
                      <i className="uil uil-check-circle text-primary">
                      </i> Personnalisation 1 information de contact</li>
                    <li className="pricing-item">Updates</li>
                    <li className="pricing-item"><del className="text-muted">Consulter qui vous a ajouté dans son portefeuille</del></li>
                    <li className="pricing-item"><del className="text-muted">Un badge plastifié de protection</del></li>
                    <li className="pricing-item">
                      <del className="text-muted">Premium support</del>
                    </li>
                  </ul>
                  <Button to="#" className="btn bg-gradiant mt-4 w-100">Sign in</Button>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} mt={4}>
              <div className="pricing-box text-center">
                <div className="px-4 py-5">
                  <div className="pricing-icon text-primary">
                    <h3>60<sup>TND</sup></h3>
                  </div>
                  <h5 className="my-4 box-heading">VIP</h5>
                  <ul className="pricing-team pricing-list list-unstyled mt-2 mb-0">
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Carte avec couleur et logo</li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Personnalisation Prénom, Nom et Profession</li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> QR Code & NFC</li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Notification d'ajout dans d'autres portefeuilles</li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Personnalisation 3 informations de contact</li>
                    <li className="pricing-item"><li className="pricing-item">Updates</li></li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Consulter qui vous a ajouté dans son portefeuille</li>
                    <li className="pricing-item"><i className="uil uil-check-circle text-primary"></i> Un badge plastifié de protection</li>
                    <li className="pricing-item text-warning">Premium support</li>
                  </ul>
                  <Button to="#" className="btn bg-gradiant mt-4 w-100">Sign in</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Pricing;