import React from 'react';
import './app-button.scss';
import AppStore from '../../assets/images/app_store_badge.svg';
import PlayStore from '../../assets/images/play_store_badge.svg';
import { Box, Link } from '@mui/material';

export default function AppButton() {
  return (
    <Box
      className="mt-4 list-unstyled mb-0 align-items-center"
    >
      <Box
        className="list-inline-item">
        <Link
          href="https://play.google.com/store/apps/details?id=com.c4e"
          rel="noreferrer noopener"
          target="_blank"
          title="Card4ever Android Mobile"
        >
          <img src={`${PlayStore}`} alt="Card4ever sur android" />
        </Link>
      </Box>
      <Box className="list-inline-item">
        <Link
          href="https://apps.apple.com/fr/app/card4ever/id6449389234"
          rel="noreferrer noopener"
          target="_blank"
          title="Card4ever IOS Mobile"
        >
          <img src={`${AppStore}`} alt="Card4ever sur ios" />
        </Link>
      </Box>
    </Box>
  );
}
