import React from 'react';
import { FacebookIcon, InstagramIcon, LinkedinIcon, MapIcon, TwitterIcon, WebIcon } from '../Icons';
import { get } from 'lodash';
import { Link } from '@mui/material';

export default function UrlButton({
  type = '',
  card,
}) {
  const urlTypes = {
    linkedin: LinkedinIcon,
    facebook: FacebookIcon,
    map: MapIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
    web: WebIcon,
  };

  const UrlIcon = (props) => {
    if (urlTypes.hasOwnProperty(type)) {
      const SelectedUrlIcon = urlTypes[type];
      return <SelectedUrlIcon {...props} />;
    }
    return <></>;
  };

  return (
    <Link
      target='_blank'
      rel='noreferrer noopener'
      href={get(card, `product.content.${type}.value`, '')}
    >
      <UrlIcon />
    </Link>
  );
}
