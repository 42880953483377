import React from 'react'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

import 'aos/dist/aos.css';
import './assets/css/style.css';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import images
import home4 from "./assets/images/home-4.png";
import img1 from "../../assets/images/user/img-1.jpg";
import img2 from "../../assets/images/user/img-2.jpg";
import img3 from "../../assets/images/user/img-3.jpg";
import img4 from "../../assets/images/user/img-4.jpg";
import img5 from "../../assets/images/user/img-5.jpg";

AOS.init({
  duration: 1800,
});


const Work = () => {
  const { t } = useTranslation('common');
  return (
    <React.Fragment>
      <section className="section work bg-light">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">{t('work.intro.title')}</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="work-pra">
                <p className="text-muted">{t('work.intro.text')}</p>
                <h4 className="my-4">{t('work.advantage.title')}</h4>
                <ul className="inline-item">
                  <li className="list-inline-item">
                    <NotificationsNoneIcon fontSize='medium' />
                    <p className="text-muted">{t('work.advantage.first')}</p>
                  </li>
                  <li className="list-inline-item">
                    <StarOutlineIcon fontSize='medium' />
                    <p className="text-muted">{t('work.advantage.second')}</p>
                  </li>
                  <li className="list-inline-item">
                    <MailOutlineIcon fontSize='medium' />
                    <p className="text-muted">{t('work.advantage.third')}</p>
                  </li>
                  <li className="list-inline-item">
                    <FavoriteBorderIcon fontSize='medium' />
                    <p className="text-muted">{t('work.advantage.fourth')}</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={6} data-aos="fade-right">
              <div className="testimonial-content">
                <div className="testimonial-right">
                  <div className="testimonial-circle">
                    <div className="media">
                      <img src={home4} className="img-fluid" alt="" />
                    </div>
                    <span className="ring1 animate-v2">
                      <img src={img1} className="img-fluid" alt="" />
                    </span>
                    <span className="ring2 animate-v3">
                      <img src={img2} className="img-fluid" alt="" />
                    </span>
                    <span className="ring3 animate-v2">
                      <img src={img3} className="img-fluid" alt="" />
                    </span>
                    <span className="ring4 animate-v3">
                      <img src={img4} className="img-fluid" alt="" />
                    </span>
                    <span className="ring5 animate-v2">
                      <img src={img5} className="img-fluid" alt="" />
                    </span>
                    <span className="ring6 animate-v3"></span>
                    <span className="ring7 animate-v2"></span>
                    <span className="ring8 animate-v3"></span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Work;