import React, { useEffect, useState } from 'react';

export default function FlashMessage({ duration = 5000, persistOnHover = null, children = true }) {
  const start = new Date();
  const [isVisible, setIsVisible] = useState(true);
  const [timer, setTimer] = useState(true);
  const [remaining, setRemaining] = useState(true);

  useEffect(() => {
    this.remaining = duration;
    resumeTimer();

    return () => {
      clearTimeout(timer);
    }
  }, []);

  const hide = () => {
    setIsVisible(false);
  }

  const resumeTimer = () => {
    window.clearTimeout(timer);
    setTimer(setTimeout(hide, remaining));
  }

  const pauseTimer = () => {
    if (persistOnHover) {
      clearTimeout(timer);

      setRemaining(remaining -= new Date() - start);
    }
  }

  return (
    isVisible ? (
      <div onMouseEnter={pauseTimer} onMouseLeave={resumeTimer} >
        {children}
      </div>
    ) : null
  )
}