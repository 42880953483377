import React from 'react';
import { get } from 'lodash';
import { WhatsappIcon } from '../Icons';
import { Button } from '@mui/material';

export default function WhatsappButton({
  card,
}) {
  const onPressWhatsapp = () => {
    const value = get(card, `product.content.whatsapp.value`, '');
    const prefix = get(card, `product.content.whatsapp.prefix`, '');
    if (prefix && value) {
      console.log(prefix, value);
    }
  };

  return (
    <Button onClick={onPressWhatsapp}>
      <WhatsappIcon />
    </Button>
  );
}
