import React, { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import 'boxicons/css/boxicons.min.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material';
import Card from './views/Card';
import AboutUs from './views/AboutUs';
import Header from './views/Header';
import Home from './views/Home';
import Features from './views/Features';
import Work from './views/Work';
import Pricing from './views/Pricing';
import Application from './views/Application';
import Contact from './views/Contact';

const ScrollToTop = React.lazy(() => import('./components/ScrollToTop'));
const Footer = React.lazy(() => import('./views/Footer'));
const PrivacyPolicy = React.lazy(() => import('./views/PrivacyPolicy'));
const TermsConditions = React.lazy(() => import('./views/TermsConditions'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#1D71B8',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Open Sans,sans-serif',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
  },
});

export default function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header />
          <Suspense fallback={<div>Chargement...</div>}>
            <ScrollToTop />
          </Suspense>
          <Routes>
            <Route exact path={'/'} element={
              <React.Suspense fallback={<>...</>}>
                <Home />
                <AboutUs />
                <Features />
                <Work />
                <Pricing />
                <Application />
                <Contact />
              </React.Suspense>
            }>
            </Route>

            <Route path={'/:id'} element={
              <React.Suspense fallback={<>...</>}>
                <Card />
              </React.Suspense>
            }>
            </Route>

            <Route path={'/privacy-policy'} element={
              <React.Suspense fallback={<>...</>}>
                <PrivacyPolicy />
              </React.Suspense>
            }>
            </Route>

            <Route path={'/terms-conditions'} element={
              <TermsConditions />
            }>
            </Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
