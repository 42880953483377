import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './assets/css/style.css';

const Contact = () => {

  const { t } = useTranslation('common');

  return (
    <React.Fragment>
      <section className="contact overflow-hidden" id="contact">
        <Container >
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">{t('contact.title')}</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col lg={8}>
              <div className="contact-box">
                <div className="custom-form mt-4">
                  <form method="post" name="myForm">
                    <p id="error-msg" style={{ opacity: 1 }}> </p>
                    <div id="simple-msg"></div>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <input name="name" id="name" type="text"
                            className="form-control contact-form" placeholder={t('contact.form.name.placeholder')} />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="form-group">
                          <input name="email" id="email" type="email"
                            className="form-control contact-form" placeholder={t('contact.form.email.placeholder')} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <input type="text" className="form-control contact-form" id="subject"
                            placeholder={t('contact.form.subject.placeholder')} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <textarea name="comments" id="comments" rows="6"
                            className="form-control contact-form h-auto"
                            placeholder={t('contact.form.message.placeholder')}></textarea>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <div className="col-lg-6">
                        <div className="text-center">
                          <input type="submit" id="submit" name="send"
                            className="submitBnt btn btn-rounded bg-gradiant" value={t('contact.form.submit')} />
                        </div>
                      </div>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="my-5 py-4 justify-content-center text-center">
            <Col md={4}>
              <div>
                <LocationOnIcon fontSize='medium' className='text-primary' />
                <h5 className="mb-1">{t('contact.address.label')}</h5>
                <p className="f-14 mb-0 text-muted">{t('contact.address.text')}</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="mt-4 mt-lg-0">
                <EmailIcon fontSize='medium' className='text-primary' />
                <h5 className="mb-1">{t('contact.email.label')}</h5>
                <p className="f-14 mb-0 text-muted">{t('contact.email.text')}</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="mt-4 mt-lg-0">
                <PhoneIcon fontSize='medium' className='text-primary' />
                <h5 className="mb-1">{t('contact.phone.label')}</h5>
                <p className="f-14 mb-0 text-muted">{t('contact.phone.text')}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="position-relative">
          <div className="contact-map">
            <iframe className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.24495251374!2d10.131276077083195!3d36.83660917223677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd331a2d03e851%3A0x557facd19acd393c!2s5%20Rue%206139%2C%20Tunis%2C%20Tunisie!5e0!3m2!1sfr!2sfr!4v1740433950671!5m2!1sfr!2sfr"
              width="100%" height="450" title="myFrame" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Contact;