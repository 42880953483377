import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import CallButton from './CallButton';
import EmailButton from './EmailButton';
import UrlButton from './UrlButton';
import WhatsappButton from './WhatsappButton';
import TextButton from './TextButton';
import { Box } from '@mui/material';

export default function Contact({
  card,
  type
}) {
  const [buttons, changeButtons] = useState([]);

  const professionalButtons = {
    email: { button: <EmailButton card={card} /> },
    phone: { button: <CallButton type='phone' card={card} /> },
    fix: { button: <CallButton type='fix' card={card} /> },
    whatsapp: { button: <WhatsappButton card={card} /> },
    linkedin: { button: <UrlButton type='linkedin' card={card} /> },
    twitter: { button: <UrlButton type='twitter' card={card} /> },
    facebook: { button: <UrlButton type='facebook' card={card} /> },
    instagram: { button: <UrlButton type='instagram' card={card} /> },
    map: { button: <UrlButton type='map' card={card} /> },
    web: { button: <UrlButton type='web' card={card} /> },
    company: { button: <TextButton type='company' card={card} /> },
  }

  useEffect(() => {
    const buttons = [];
    if (!_.isEmpty(card?.product) && !_.isEmpty(card)) {
      Object.keys(card.product.content).forEach((key) => {
        const professionalButton = professionalButtons[key];
        if (professionalButton) {
          buttons.push({ alt: key, button: professionalButton['button'] });
        }
      });
    };

    changeButtons(buttons);
  }, [card])

  const renderItem = (item) => {
    return <Box style={{
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      aspectRatio: 1,
      borderRadius: 10,
      backgroundColor: '#FFFFFF',
    }}>{item?.button}</Box>;
  };

  return (
    <Box sx={{ flexGrow: 1, marginX: 4, marginTop: 8, marginBottom: 2 }}>
      <Grid container columnSpacing={0.5} rowSpacing={2}>
        {Array.from(buttons).map((button, index) => {
          return (<Grid item xs={4} sm={4} md={4} key={index}>{renderItem(button)}</Grid>)
        })}
      </Grid>
    </Box>
  );
}
