  import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useFetch(url) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(url);
        setLoading(false);
        setData(response);
      } catch (error) {
        setError('An error occurred. Awkward..');
        console.error(error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);
  return { data, loading, error };
}