import React from 'react';
import { get } from 'lodash';
import { EmailIcon } from '../Icons';
import { Button } from '@mui/material';

export default function EmailButton({
  card
}) {
  const pressOnEmail = (e) => {
    const value = get(card, 'product.content.email.value', '');
    if (value) {
      window.location.href = `mailto:${value}`;
      e.preventDefault();
    }
  }

  return (
    <Button
      onClick={pressOnEmail}
    >
      <EmailIcon />
    </Button>
  );
};
