import { AddBox, Business, Email, Facebook, Fax, Instagram, Link, LinkedIn, Map, Phone, Twitter, WhatsApp } from '@mui/icons-material';
import React from 'react';

const CompanyIcon = (props) => <Business {...props} color='secondary' sx={{ fontSize: 40 }} />
const EmailIcon = (props) => <Email {...props} color='secondary' sx={{ fontSize: 40 }} />;
const PhoneIcon = (props) => <Phone {...props} color='secondary' sx={{ fontSize: 40 }} />;
const FixPhoneIcon = (props) => <Fax {...props} color='secondary' sx={{ fontSize: 40 }} />;
const MapIcon = (props) => <Map {...props} color='secondary' sx={{ fontSize: 40 }} />;
const WebIcon = (props) => <Link {...props} color='secondary' sx={{ fontSize: 40 }} />;
const InstagramIcon = (props) => <Instagram {...props} color='secondary' sx={{ fontSize: 40 }} />;
const FacebookIcon = (props) => <Facebook {...props} color='secondary' sx={{ fontSize: 40 }} />;
const WhatsappIcon = (props) => <WhatsApp {...props} color='secondary' sx={{ fontSize: 40 }} />;
const LinkedinIcon = (props) => <LinkedIn {...props} color='secondary' sx={{ fontSize: 40 }} />;
const TwitterIcon = (props) => <Twitter {...props} color='secondary' sx={{ fontSize: 40 }} />;
const AddIcon = (props) => <AddBox {...props} color='secondary' sx={{ fontSize: 40 }} />;

export {
  CompanyIcon,
  EmailIcon,
  PhoneIcon,
  FixPhoneIcon,
  MapIcon,
  WebIcon,
  InstagramIcon,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TwitterIcon,
  AddIcon,
};
