import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import PeopleIcon from '@mui/icons-material/People';
import ShareIcon from '@mui/icons-material/Share';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import SettingsIcon from '@mui/icons-material/Settings';
import WalletIcon from '@mui/icons-material/Wallet';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import 'aos/dist/aos.css';
import './assets/css/style.css';
// import images
import features from "./assets/images/features.png";

AOS.init({
  duration: 1800,
});

const Features = () => {
  const { t } = useTranslation('common');
  return (
    <React.Fragment>
      <section className="section features" id="features">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">{t('feature.title')}</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col lg={4} md={10} data-aos="fade-right" >
              <div className="features-box text-end">
                <div className="features-icon float-end ms-2">
                  <PeopleIcon />
                </div>
                <div className="pe-3 me-5">
                  <h5 className="f-15 text-uppercase">{t('feature.section1.title')}</h5>
                  <p className="text-muted mt-3">{t('feature.section1.text')}</p>
                </div>
              </div>

              <div className="features-box mt-5 text-end">
                <div className="features-icon float-end ms-2">
                  <ShareIcon />
                </div>
                <div className="pe-3 me-5">
                  <h5 className="f-15 text-uppercase">{t('feature.section2.title')}</h5>
                  <p className="text-muted mt-3">{t('feature.section2.text')}</p>
                </div>
              </div>

              <div className="features-box mt-5 text-end">
                <div className="features-icon float-end ms-2">
                  <TapAndPlayIcon />
                  <i className="uil uil-mobile-android-alt"></i>
                </div>
                <div className="pe-3 me-5">
                  <h5 className="f-15 text-uppercase">{t('feature.section3.title')}</h5>
                  <p className="text-muted mt-3">{t('feature.section3.text')}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center">
                <img src={features} className="img-fluid" data-aos="zoom-in" alt="" />
              </div>
            </Col>
            <Col lg={4} md={10} data-aos="fade-left">
              <div className="features-box">
                <div className="features-icon float-start me-2">
                  <SettingsIcon />
                </div>
                <div className="ps-3 ms-5">
                  <h5 className="f-15 text-uppercase">{t('feature.section4.title')}</h5>
                  <p className="text-muted mt-3">{t('feature.section4.text')}</p>
                </div>
              </div>
              <div className="features-box mt-5">
                <div className="features-icon float-start me-2">
                  <WalletIcon />
                </div>
                <div className="ps-3 ms-5">
                  <h5 className="f-15 text-uppercase">{t('feature.section5.title')}</h5>
                  <p className="text-muted mt-3">
                    {t('feature.section5.text')}
                  </p>
                </div>
              </div>
              <div className="features-box mt-5">
                <div className="features-icon float-start me-2">
                  <PersonSearchIcon />
                </div>
                <div className="ps-3 ms-5">
                  <h5 className="f-15 text-uppercase">{t('feature.section6.title')}</h5>
                  <p className="text-muted mt-3">
                    {t('feature.section6.text')}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Features;